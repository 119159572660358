import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { Container } from 'layout';

import { IStepsBreadcrumbsTypes } from './model';

import './StepsBreadcrumbs.scss';

const StepsBreadcrumbs: FC<IStepsBreadcrumbsTypes> = ({
  stepsBreadcrumbsTitles,
  breadcrumbsActiveNumber = 0,
}) => (
  <div className="steps-breadcrumbs" data-testid="steps-breadcrumbs">
    <Container fluid>
      {stepsBreadcrumbsTitles?.length ? (
        <ul className="steps-breadcrumbs__list">
          {stepsBreadcrumbsTitles.map(({ value }, index) => (
            <li
              key={value}
              className={classNames('steps-breadcrumbs__list-item', {
                [`active-${index}`]: breadcrumbsActiveNumber - 1 > index,
                [`active-half-${index}`]: breadcrumbsActiveNumber - 1 === index,
              })}
            >
              <span className="steps-breadcrumbs__list-item-text">{value}</span>
            </li>
          ))}
        </ul>
      ) : null}
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentStepsBreadcrumbs on TStepsBreadcrumbs {
    properties {
      stepsBreadcrumbsTitles {
        value
      }
    }
    structure
  }
`;

export default StepsBreadcrumbs;
