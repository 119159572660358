import { WaterCalculatorTypes } from '@shared/types';

const getCalculatorValues = (stepsData: WaterCalculatorTypes.IStepsData) => {
  const stepResults = [
    (stepsData.xOccasion * 12.5) / 7 / stepsData.xPerson, // step 2
    (stepsData.washingMachineAge * stepsData.occasionMachineAge) / 7 / stepsData.xPerson, // step 3
    16.5, // step 4
    stepsData.showerTimesPerDay * stepsData.showerTimesMinutes * stepsData.waterSavingShowerHead, // step 5
    (stepsData.isBathtub * stepsData.timeFillingBathtub) / 7, // step 7
    stepsData.timesToilets * stepsData.isWaterSavingToiletTank, // step 9
    (stepsData.timesDishwasherPerWeek * stepsData.isDishwasherEcoProgram) / 7 / stepsData.xPerson, // step 11
    stepsData.timesWashingWithHands * 50, // step 12
    (stepsData.timeWateringManually * 12) / 7 / stepsData.xPerson, // step 14
    (stepsData.quantitySprinklerHeads * stepsData.timesSummerIrrigation * 23.3) / 4, // step 16
    (stepsData.timesSummerDripSystem * stepsData.dripSystemLength * 0.3) / 7 / stepsData.xPerson, // step 18
    stepsData.poolSize / stepsData.xPerson, // step 20
    stepsData.dietType, // step 21
    (stepsData.carTravelLengthPerWeek * 231.5) / 7, // step 22
    (stepsData.trainTravelLengthPerWeek * 14) / 7, // step 23
    stepsData.shoppingHabits, // step 24
  ];

  return stepResults.reduce(
    (partialSum, stepResult) =>
      partialSum +
      (stepResult >= 0 && !Number.isNaN(stepResult) && stepResult !== Infinity ? stepResult : 0),
    0
  );
};
export default getCalculatorValues;
