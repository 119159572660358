import React, { FC, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import { Container, DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import StepsField from 'components/StepsField';
import { CONTENT_PARTS, FIELD_TYPES } from 'templates/WaterCalculator/constants';
import { scrollToClassName } from 'utils/browser';

import { IStepProperties } from './model';

import './Steps.scss';

const Steps: FC<IStepProperties> = ({
  errorText,
  previousLabel,
  nextLabel,
  decreaseLabel,
  increaseLabel,
  stepList,
  setActiveContentPart,
  stepsData,
  setStepsData,
  setBreadcrumbsActiveNumber,
}) => {
  const [isNextStepActive, setIsNextStepActive] = useState<boolean>(false);
  const [stepsHistory, setStepsHistory] = useState<Array<number>>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [nextStepNumber, setNextStepNumber] = useState<number | null>(null);
  const decreaseStep = () => {
    setActiveStep(stepsHistory[stepsHistory.length - 1] || activeStep - 1);
    setStepsHistory(stepsHistory.slice(0, -1));
  };
  const increaseStep = () => {
    setActiveStep(nextStepNumber > activeStep ? nextStepNumber - 1 : activeStep + 1);
    setNextStepNumber(null);
    setIsNextStepActive(false);
    setStepsHistory([...stepsHistory, activeStep]);
    if (stepList.length - 1 === activeStep) {
      setActiveContentPart(CONTENT_PARTS.resultState);
      scrollToClassName('person-banner');
    }
  };

  useEffect(() => {
    setBreadcrumbsActiveNumber(stepList[activeStep].properties.breadcrumbsActive);
  }, [stepList[activeStep].properties]);

  const isMandatoryChoice = !stepList[activeStep].properties.stepFields.find(
    (e) => e.properties.fieldType[0] === FIELD_TYPES.number
  );

  return (
    <div className="steps" data-testid="steps">
      <Container fluid>
        {stepList[activeStep] ? (
          <div className="steps__list">
            <div className="steps__item">
              {stepList[activeStep].properties.image?.[0]?.properties?.image ? (
                <div className="steps__item-image">
                  <GatsbyImage
                    image={stepList[activeStep].properties.image[0].properties.image}
                    alt={stepList[activeStep].properties.image[0].properties.imageAlt}
                  />
                </div>
              ) : null}

              <DangerouslySetInnerHtml
                className="steps__item-description"
                html={stepList[activeStep].properties.description}
              />

              {stepList[activeStep].properties.additionalDescription ? (
                <div className="steps__item-additional-content">
                  {stepList[activeStep].properties.additionalDescriptionImage[0].properties
                    .image ? (
                    <div className="steps__item-additional-image">
                      <GatsbyImage
                        image={
                          stepList[activeStep].properties.additionalDescriptionImage[0].properties
                            .image
                        }
                        alt={
                          stepList[activeStep].properties.additionalDescriptionImage[0].properties
                            .imageAlt
                        }
                      />
                    </div>
                  ) : null}
                  <div className="steps__item-additional-description-wrapper">
                    <DangerouslySetInnerHtml
                      className="steps__item-additional-description"
                      html={stepList[activeStep].properties.additionalDescription}
                    />
                  </div>
                </div>
              ) : null}

              {stepList[activeStep].properties.stepFields?.length ? (
                <div
                  className={classnames('steps__item-fields', {
                    'small--style':
                      stepList[activeStep].properties.stepFields?.length === 2 && isMandatoryChoice,
                  })}
                >
                  {stepList[activeStep].properties.stepFields.map(
                    ({
                      properties: {
                        alias,
                        fieldType,
                        label,
                        labelPrev,
                        ariaLabel,
                        additionalValue,
                        minimalValue,
                        nextStep,
                      },
                    }) => (
                      <StepsField
                        key={`${alias[0]}${label}`}
                        alias={alias}
                        fieldType={fieldType}
                        label={label}
                        labelPrev={labelPrev}
                        decreaseLabel={decreaseLabel}
                        increaseLabel={increaseLabel}
                        stepsData={stepsData}
                        setStepsData={setStepsData}
                        ariaLabel={ariaLabel}
                        additionalValue={additionalValue}
                        minimalValue={minimalValue}
                        setNextStepNumber={setNextStepNumber}
                        nextStep={nextStep}
                        setIsNextStepActive={setIsNextStepActive}
                        isNextStepActive={isNextStepActive}
                        isMandatoryChoice={isMandatoryChoice}
                      />
                    )
                  )}

                  <DangerouslySetInnerHtml
                    className="steps__item-field-description"
                    html={stepList[activeStep].properties.fieldDescription}
                  />
                </div>
              ) : null}
            </div>

            <div className="steps__pagination">
              <button
                className={classnames('steps__pagination-button previous--style', {
                  'hidden--style': activeStep === 0,
                })}
                type="button"
                aria-label={previousLabel}
                onClick={decreaseStep}
              >
                {previousLabel}
              </button>
              <button
                className="steps__pagination-button next--style"
                type="button"
                aria-label={nextLabel}
                onClick={increaseStep}
                disabled={!isNextStepActive}
              >
                {nextLabel}
              </button>
            </div>

            {isMandatoryChoice && !isNextStepActive && errorText ? (
              <DangerouslySetInnerHtml className="steps__error-text" html={errorText} />
            ) : null}
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentCalculatorSteps on TCalculatorSteps {
    properties {
      errorText
      previousLabel
      nextLabel
      decreaseLabel
      increaseLabel
      stepList {
        properties {
          image {
            properties {
              imageAlt
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 210, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          description
          stepFields {
            properties {
              fieldType
              label
              alias
              labelPrev
              ariaLabel
              nextStep
              additionalValue
              minimalValue
            }
          }
          breadcrumbsActive
          additionalDescription
          fieldDescription
          additionalDescriptionImage {
            properties {
              imageAlt
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxHeight: 48, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    structure
  }
`;

export default Steps;
