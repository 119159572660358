import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import getCalculatorValues from 'utils/getCalculatorValues';
import { CONTENT_PARTS, LIMITS } from 'templates/WaterCalculator/constants';
import { scrollToClassName } from 'utils/browser';

import { ICalculatorResultsTypes } from './model';

import './CalculatorResults.scss';

const CalculatorResults: FC<ICalculatorResultsTypes> = ({
  title,
  measureCalculation,
  lowResultSubTitle,
  mediumResultSubTitle,
  highResultSubTitle,
  lowResultImage,
  mediumResultImage,
  highResultImage,
  lowResultContent,
  content,
  highResultContent,
  stepsData,
  contentImage,
  disclaimer,
  cta,
  setActiveContentPart,
  setStepsData,
}) => {
  const result = Math.round(getCalculatorValues(stepsData));

  const resultContentSet =
    result < LIMITS.lowLimit
      ? {
          resultSubTitle: lowResultSubTitle,
          resultImage: lowResultImage,
          resultContent: lowResultContent,
        }
      : result > LIMITS.highLimit
      ? {
          resultSubTitle: highResultSubTitle,
          resultImage: highResultImage,
          resultContent: highResultContent,
        }
      : {
          resultSubTitle: mediumResultSubTitle,
          resultImage: mediumResultImage,
          resultContent: content,
        };

  const restartSteps = () => {
    setStepsData({});
    scrollToClassName('person-banner');
    setActiveContentPart(CONTENT_PARTS.previewState);
  };

  return (
    <div className="calculator-results" data-testid="calculator-results">
      <Container fluid>
        <DangerouslySetInnerHtml className="calculator-results__title" html={title} />
        <p className="calculator-results__result">
          {result}
          {measureCalculation}
        </p>
        <DangerouslySetInnerHtml
          className="calculator-results__sub-title"
          html={resultContentSet.resultSubTitle}
        />

        {resultContentSet.resultImage?.[0]?.properties?.image ? (
          <div className="calculator-results__image">
            <GatsbyImage
              image={resultContentSet.resultImage[0].properties.image}
              alt={resultContentSet.resultImage[0].properties.imageAlt}
              objectFit="contain"
              isLazyLoading={false}
              fadeIn={false}
              useFocalPoint
            />
          </div>
        ) : null}

        <DangerouslySetInnerHtml
          className="calculator-results__content"
          html={resultContentSet.resultContent}
        />

        {contentImage?.[0]?.properties?.image ? (
          <div className="calculator-results__content-image">
            <GatsbyImage
              image={contentImage[0].properties.image}
              alt={contentImage[0].properties.imageAlt}
              objectFit="contain"
              isLazyLoading={false}
              fadeIn={false}
              useFocalPoint
            />
          </div>
        ) : null}

        <DangerouslySetInnerHtml className="calculator-results__disclaimer" html={disclaimer} />

        {cta?.length ? (
          <Button
            classes="calculator-results__cta"
            to={cta[0].properties.link[0].url}
            ariaLabel={cta[0].properties.ariaLabel}
            target={cta[0].properties.link[0].target}
            variant="secondary-blue"
            clickHandler={restartSteps}
          >
            {cta[0].properties.link[0].name}
          </Button>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentResultsContent on TResultsContent {
    properties {
      title
      measureCalculation
      lowResultSubTitle
      mediumResultSubTitle
      highResultSubTitle
      lowResultContent
      content
      highResultContent
      disclaimer
      cta {
        properties {
          ariaLabel
          link {
            name
            target
            url
          }
        }
      }
      contentImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 880) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      lowResultImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 210) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mediumResultImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 210) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      highResultImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 210) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default CalculatorResults;
