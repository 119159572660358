import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from 'gatsby-theme-husky/src/layout/Layout';
import PersonBanner from 'components/PersonBanner';
import WaterCalculatorContent from 'components/WaterCalculatorContent';
import StepsBreadcrumbs from 'components/StepsBreadcrumbs';
import Steps from 'components/Steps';
import CalculatorResults from 'components/CalculatorResults';
import BodyRenderer from 'common/BodyRenderer';
import { WaterCalculatorTypes } from '@shared/types';
import { CONTENT_PARTS } from './constants';

const elements = {
  'Person Banner': ({ properties }, keyId) => <PersonBanner key={keyId} {...properties} />,
  'Water Calculator Content': ({ properties }, keyId) => (
    <WaterCalculatorContent key={keyId} {...properties} />
  ),
};

const stepsElements = {
  'Person Banner': ({ properties }, keyId) => <PersonBanner key={keyId} {...properties} />,
  'Steps Breadcrumbs': ({ properties }, keyId) => <StepsBreadcrumbs key={keyId} {...properties} />,
  'Calculator Steps': ({ properties }, keyId) => <Steps key={keyId} {...properties} />,
};

const resultElements = {
  'Person Banner': ({ properties }, keyId) => <PersonBanner key={keyId} {...properties} />,
  'Results Content': ({ properties }, keyId) => <CalculatorResults key={keyId} {...properties} />,
};

const WaterCalculator: FC<WaterCalculatorTypes.IProperties> = ({
  pageContext: { link, searchUrl, breadCrumbs },
  data: {
    page: {
      nodes: [
        {
          body,
          defaultCompositions,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          seoCanonicalUrl,
          alternateUrls,
          ogImageUrl,
          stepsBody,
          resultsBody,
        },
      ],
    },
  },
}) => {
  const { waiSettings } = defaultCompositions;
  const [activeContentPart, setActiveContentPart] = useState(CONTENT_PARTS.previewState);
  const [stepsData, setStepsData] = useState<object>({});
  const [breadcrumbsActiveNumber, setBreadcrumbsActiveNumber] = useState<number>(0);
  const imageUrl =
    ogImageUrl || body?.[0]?.properties?.contentImage?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = body?.[0]?.properties?.contentImage?.[0]?.properties?.imageAlt;

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        seoCanonicalUrl,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        isHideHeader: false,
        isHideFooter: false,
        imageUrl,
        imageAlt,
      }}
    >
      {activeContentPart === CONTENT_PARTS.previewState ? (
        <BodyRenderer
          bodyData={body}
          bodyStructure={elements}
          bodyItemProps={{ breadCrumbs, setActiveContentPart }}
        />
      ) : null}

      {activeContentPart === CONTENT_PARTS.stepsState ? (
        <BodyRenderer
          bodyData={stepsBody}
          bodyStructure={stepsElements}
          bodyItemProps={{
            breadCrumbs,
            setActiveContentPart,
            stepsData,
            setStepsData,
            breadcrumbsActiveNumber,
            setBreadcrumbsActiveNumber,
          }}
        />
      ) : null}
      {activeContentPart === CONTENT_PARTS.resultState ? (
        <BodyRenderer
          bodyData={resultsBody}
          bodyStructure={resultElements}
          bodyItemProps={{ breadCrumbs, stepsData, setActiveContentPart, setStepsData }}
        />
      ) : null}
    </Layout>
  );
};
export const query = graphql`
  query allWaterCalculator($link: String = "") {
    page: allWaterCalculator(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        seoCanonicalUrl {
          url
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        defaultCompositions {
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          waiSettings {
            skipText
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          promoPlatformForm {
            ...FragmentPromoPlatformForm
          }
        }
        body {
          ... on TPersonBanner {
            ...FragmentPersonBanner
          }
          ... on TWaterCalculatorContent {
            ...FragmentWaterCalculatorContent
          }
        }
        stepsBody {
          ... on TPersonBanner {
            ...FragmentPersonBanner
          }
          ... on TStepsBreadcrumbs {
            ...FragmentStepsBreadcrumbs
          }
          ... on TCalculatorSteps {
            ...FragmentCalculatorSteps
          }
        }
        resultsBody {
          ... on TPersonBanner {
            ...FragmentPersonBanner
          }
          ... on TResultsContent {
            ...FragmentResultsContent
          }
        }
      }
    }
  }
`;

export default WaterCalculator;
