import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import { Container, DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import BreadCrumbs from 'common/BreadCrumbs';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { IPersonBannerTypes } from './model';

import './PersonBanner.scss';

const PersonBanner: FC<IPersonBannerTypes> = ({
  title,
  contentImage,
  imageText,
  backgroundImage,
  backgroundImageMobile,
  breadCrumbs,
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div
      className={classnames('person-banner', {
        'without--images': contentImage?.length === 0 && backgroundImage?.length === 0,
      })}
      data-testid="person-banner"
    >
      <Container fluid>
        <div className="person-banner__grid">
          <div className="person-banner__header">
            {breadCrumbs?.length ? (
              <BreadCrumbs data={breadCrumbs} popIndex={0} isHideLastSeparator />
            ) : null}

            <DangerouslySetInnerHtml className="person-banner__title" html={title} />
          </div>

          {contentImage?.[0]?.properties?.image ? (
            <div className="person-banner__image">
              <GatsbyImage
                image={contentImage[0].properties.image}
                alt={contentImage[0].properties.imageAlt}
                objectFit="contain"
                isLazyLoading={false}
                fadeIn={false}
                useFocalPoint
              />

              <DangerouslySetInnerHtml className="person-banner__image-text" html={imageText} />
            </div>
          ) : null}
        </div>
      </Container>

      {backgroundImage?.[0]?.properties?.image ? (
        <div className="person-banner__background">
          {isMobile ? (
            <GatsbyImage
              image={backgroundImageMobile[0].properties.image}
              alt={backgroundImageMobile[0].properties.imageAlt}
              isLazyLoading={false}
              fadeIn={false}
            />
          ) : (
            <GatsbyImage
              image={backgroundImage[0].properties.image}
              alt={backgroundImage[0].properties.imageAlt}
              isLazyLoading={false}
              fadeIn={false}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentPersonBanner on TPersonBanner {
    properties {
      title
      contentImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      imageText
      backgroundImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      backgroundImageMobile {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default PersonBanner;
