import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import { FIELD_TYPES } from 'templates/WaterCalculator/constants';

import { IStepFieldProperties } from './model';

import './StepsField.scss';

const StepsField: FC<IStepFieldProperties> = ({
  alias,
  fieldType,
  label,
  labelPrev,
  decreaseLabel,
  increaseLabel,
  stepsData,
  setStepsData,
  ariaLabel,
  additionalValue,
  minimalValue,
  setNextStepNumber,
  nextStep,
  setIsNextStepActive,
  isNextStepActive,
  isMandatoryChoice,
}) => {
  const minimalInputValue = minimalValue || 0;
  const [numberValue, setNumberValue] = useState<number>(stepsData[alias[0]] || minimalValue || 0);
  const rewriteData = () => {
    setStepsData({
      ...stepsData,
      [alias[0]]: additionalValue || numberValue,
    });
  };
  const decreaseQuantity = () =>
    setNumberValue(numberValue === minimalInputValue ? minimalInputValue : numberValue - 1);
  const increaseQuantity = () => {
    setNumberValue(numberValue + 1);
  };
  const inputHandle = (event) => {
    const value = event.target.value === '' ? 0 : parseInt(event.target.value, 10);
    setNumberValue(value >= 0 ? value : numberValue);
  };
  const chooseVariant = () => {
    nextStep && setNextStepNumber(nextStep);
    setIsNextStepActive(true);
    rewriteData();
  };

  useEffect(() => {
    if (fieldType[0] === FIELD_TYPES.number || stepsData[alias[0]] === additionalValue) {
      setIsNextStepActive(true);
      rewriteData();
    }
  }, [numberValue]);

  useEffect(() => {
    nextStep && setNextStepNumber(nextStep);
  }, [nextStep]);

  return (
    <div className="steps-field" data-testid="steps-field">
      {fieldType[0] === FIELD_TYPES.number ? (
        <div className="steps-field__number">
          <DangerouslySetInnerHtml
            html={labelPrev}
            className="steps-field__label previous--style"
          />
          <button
            type="button"
            disabled={numberValue === 0}
            onClick={decreaseQuantity}
            className="steps-field__number-button"
          >
            {decreaseLabel}
          </button>
          <input
            onInput={inputHandle}
            value={numberValue >= minimalInputValue ? numberValue : minimalInputValue}
            className="steps-field__number-input"
            type="text"
          />
          <button
            type="button"
            onClick={increaseQuantity}
            className="steps-field__number-button increase--style"
          >
            {increaseLabel}
          </button>
          <DangerouslySetInnerHtml html={label} className="steps-field__label" />
        </div>
      ) : (
        <DangerouslySetInnerHtml
          element="button"
          html={label}
          aria-label={ariaLabel}
          type="button"
          onClick={chooseVariant}
          className={classnames('btn btn--secondary-blue steps-field__number-checkbox', {
            'active--style': stepsData[alias[0]] === additionalValue && isNextStepActive,
            'unselected--style':
              isMandatoryChoice && isNextStepActive && stepsData[alias[0]] !== additionalValue,
          })}
        />
      )}
    </div>
  );
};

export default StepsField;
