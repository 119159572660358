import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import { CONTENT_PARTS } from 'templates/WaterCalculator/constants';
import { scrollToClassName } from 'utils/browser';

import { IWaterCalculatorContentTypes } from './model';

import './WaterCalculatorContent.scss';

const WaterCalculatorContent: FC<IWaterCalculatorContentTypes> = ({
  title,
  contentImage,
  content,
  button,
  setActiveContentPart,
}) => {
  const startSteps = () => {
    scrollToClassName('person-banner');
    setActiveContentPart(CONTENT_PARTS.stepsState);
  };

  return (
    <div className="water-calculator-content" data-testid="water-calculator-content">
      <Container fluid>
        <DangerouslySetInnerHtml className="water-calculator-content__content" html={title} />

        {button?.length ? (
          <Button
            variant="secondary-blue"
            classes="water-calculator-content__button"
            ariaLabel={button[0].properties.ariaLabel}
            clickHandler={startSteps}
          >
            {button[0].properties.label}
          </Button>
        ) : null}

        <DangerouslySetInnerHtml
          className="water-calculator-content__content center--style"
          html={content}
        />

        {contentImage?.[0]?.properties?.image ? (
          <div className="water-calculator-content__image">
            <GatsbyImage
              image={contentImage[0].properties.image}
              alt={contentImage[0].properties.imageAlt}
              isLazyLoading={false}
              fadeIn={false}
            />
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentWaterCalculatorContent on TWaterCalculatorContent {
    properties {
      title
      content
      button {
        ... on TButton {
          ...FragmentButton
        }
      }
      contentImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 158) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
    structure
  }
`;

export default WaterCalculatorContent;
