export const LIMITS = {
  lowLimit: 7000,
  highLimit: 7500,
};

export const FIELD_TYPES = {
  number: 'number',
  button: 'button',
};

export const CONTENT_PARTS = {
  previewState: 0,
  stepsState: 1,
  resultState: 2,
};
